<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker v-model="state.optionFields.startDate.val" :inputAttributes="{class: 'form-control'}"/>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{class: 'form-control'}"/>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Type</label>
                <select class="form-control" v-model="state.optionFields.faultGroupID.val">
                    <option value="0">All</option>
                    <option v-for="[id, type] in cache.trackerCache.TRACKER_MER_REMAKE_TYPES" v-bind:key="id" :value="id">{{ type }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shipper</label>
                <Typeahead cdi_type="shipping_co" v-bind:ID.sync="state.optionFields.shippingCoID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer</label>
                <Typeahead cdi_type="user" v-bind:ID.sync="state.optionFields.distributorID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type="checkbox" checked :true-value="1" :false-value="0" v-model="state.optionFields.creditPending.val"> Pending Credit Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Order ID</label>
                <input type="text" class="form-control" v-model="state.optionFields.trackerID.val">
            </div>

            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Resolution</label>
                <select class="cdi_select form-control" v-model="state.optionFields.resolutionID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, resolutionType] in cache.trackerCache.TRACKER_MER_RESOLUTIONS" v-bind:key="cdiLookupID" :value="cdiLookupID">{{ resolutionType }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Sales Secondary</label>
                <select class="cdi_select form-control" v-model="state.optionFields.secondarySalesID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, secondaryType] in cache.trackerCache.TRACKER_MER_SECONDARY_SALES" v-bind:key="cdiLookupID" :value="cdiLookupID">{{ secondaryType }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Design Secondary</label>
                <select class="cdi_select form-control" v-model="state.optionFields.secondaryDesignID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, secondaryType] in cache.trackerCache.TRACKER_MER_SECONDARY_DESIGN" v-bind:key="cdiLookupID" :value="cdiLookupID">{{ secondaryType }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Operations Secondary</label>
                <select class="cdi_select form-control" v-model="state.optionFields.secondaryOperationsID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, secondaryType] in cache.trackerCache.TRACKER_MER_SECONDARY_OPERATIONS" v-bind:key="cdiLookupID" :value="cdiLookupID">{{ secondaryType }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Task</label>
                <select class="cdi_select form-control" v-model="state.optionFields.taskID.val">
                    <option value="0">All</option>
                    <option v-for="[taskID, task] in cache.taskCache.SHOP_TASKS" v-bind:key="taskID" :value="taskID">{{ task }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Regional Sales Rep</label>
                <Typeahead cdi_type='sales_employee' v-bind:ID.sync='state.optionFields.assigned_sales.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Support Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.inside_sales.val" />
            </div>
            <div class="col-xs-12">
                <button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js"
    import DatePicker from "../utils/DatePicker";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import trackerCache from "@/cache/tracker.cache.js";
    import taskCache from "@/cache/task.cache.js";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Options",
        components: {
            DatePicker,
            Typeahead
        },
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    trackerCache,
                    taskCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>